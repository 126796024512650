// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file utils/v1/utils.proto (package utils.v1, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file utils/v1/utils.proto.
 */
export const file_utils_v1_utils = /*@__PURE__*/
  fileDesc("ChR1dGlscy92MS91dGlscy5wcm90bxIIdXRpbHMudjEiMQoFU3RhdGUSEQoJaXNfYWN0aXZlGAEgASgIEhUKDWlzX2RlYWN0aXZhdGUYAiABKAgiKQoEQm9vbBIPCgdpc190cnVlGAEgASgIEhAKCGlzX2ZhbHNlGAIgASgIIjsKEVBhZ2luYXRpb25SZXF1ZXN0EhEKCXBhZ2Vfc2l6ZRgBIAEoAxITCgtwYWdlX251bWJlchgCIAEoAyJhChJQYWdpbmF0aW9uUmVzcG9uc2USFAoMY3VycmVudF9wYWdlGAEgASgDEhEKCXBhZ2Vfc2l6ZRgCIAEoAxINCgV0b3RhbBgDIAEoAxITCgt0b3RhbF9wYWdlcxgEIAEoA0JBWj9naXQudG1wcm94eS1pbmZyYS5jb20vYWxnby9hbGdvcHJveHktcHJvdG8vZ2VuL3V0aWxzL3YxO3V0aWxzdjFiBnByb3RvMw");

/**
 * Describes the message utils.v1.State.
 * Use `create(StateSchema)` to create a new message.
 */
export const StateSchema = /*@__PURE__*/
  messageDesc(file_utils_v1_utils, 0);

/**
 * Describes the message utils.v1.Bool.
 * Use `create(BoolSchema)` to create a new message.
 */
export const BoolSchema = /*@__PURE__*/
  messageDesc(file_utils_v1_utils, 1);

/**
 * Describes the message utils.v1.PaginationRequest.
 * Use `create(PaginationRequestSchema)` to create a new message.
 */
export const PaginationRequestSchema = /*@__PURE__*/
  messageDesc(file_utils_v1_utils, 2);

/**
 * Describes the message utils.v1.PaginationResponse.
 * Use `create(PaginationResponseSchema)` to create a new message.
 */
export const PaginationResponseSchema = /*@__PURE__*/
  messageDesc(file_utils_v1_utils, 3);

