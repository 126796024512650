export const ROUTES = {
  AUTH: '/auth',
  AUTH_LOGIN: '/auth/login',
  AUTH_FORGOT_PASSWORD: '/auth/forgot-password',
  ACCOUNTS: '/accounts',
  ACCOUNTS_USER: '/accounts/users',
  ACCOUNTS_ROLES: '/accounts/roles',
  ACCOUNTS_SERVICES: '/accounts/services',
  ACCOUNTS_PATHS: '/accounts/paths',
  ACCOUNTS_POLICIES: '/accounts/policies',
  ACCOUNTS_APPS: '/accounts/apps',
  ACCOUNTS_CONFIGS: '/accounts/configs',
  ACCOUNTS_TOTP: '/accounts/totp',
  CONTROL_PLAN: '/controlPlan',
  CONTROL_PLAN_DNS: '/controlPlan/dns',
  CONTROL_PLAN_TELCO: '/controlPlan/telco',
  CONTROL_PLAN_LOCATION: '/controlPlan/location',
  RESIDENTIAL: '/residential',
  RESIDENTIAL_ACCOUNT: '/residential/account',
  RESIDENTIAL_NODE: '/residential/node',
  RESIDENTIAL_PORT: '/residential/port',
  RESIDENTIAL_DEVICE: '/residential/device',
  BILLINGS: '/billings',
  BILLINGS_MERCHANT: '/billings/merchant',
  BILLINGS_MERCHANT_PLAN: '/billings/merchant/plan',
  BILLINGS_MERCHANT_PROFILE: '/billings/merchant/profile',
  BILLINGS_TRANSACTION: '/billings/transactions',
  BILLINGS_PAYMENTS: '/billings/payments',
  BILLINGS_SUBSCRIPTION: '/billings/subscriptions',
  PROXY_MANAGER: '/proxy-manager',
  PROXY_MANAGER_BACK_CONNECT: '/proxy-manager/back-connect',
};
