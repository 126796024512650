// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file algoenum/v1/app_country.proto (package algoenum.v1, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file algoenum/v1/app_country.proto.
 */
export const file_algoenum_v1_app_country = /*@__PURE__*/
  fileDesc("Ch1hbGdvZW51bS92MS9hcHBfY291bnRyeS5wcm90bxILYWxnb2VudW0udjEqUQoKQXBwQ291bnRyeRIbChdBUFBfQ09VTlRSWV9VTlNQRUNJRklFRBAAEhIKDkFQUF9DT1VOVFJZX1ZOEAESEgoOQVBQX0NPVU5UUllfV1cQAkJHWkVnaXQudG1wcm94eS1pbmZyYS5jb20vYWxnby9hbGdvcHJveHktcHJvdG8vZ2VuL2FsZ29lbnVtL3YxO2FsZ29lbnVtdjFiBnByb3RvMw");

/**
 * Describes the enum algoenum.v1.AppCountry.
 */
export const AppCountrySchema = /*@__PURE__*/
  enumDesc(file_algoenum_v1_app_country, 0);

/**
 * @generated from enum algoenum.v1.AppCountry
 */
export const AppCountry = /*@__PURE__*/
  tsEnum(AppCountrySchema);

