import * as AuthProto from '../algoproxy-proto/genjs/auth/v1/backoffice_auth_pb';
import serviceGrpc from './grpc';

const authServiceGrpc = (method, schema) =>
  serviceGrpc({
    method,
    schema,
    service: AuthProto.BackofficeAuthService,
  });

const fetchApps = authServiceGrpc('fetchApp', AuthProto.BackofficeAuthServiceFetchAppRequestSchema);
const createApp = authServiceGrpc('createApp', AuthProto.BackofficeAuthServiceCreateAppRequestSchema);
const updateApp = authServiceGrpc('updateApp', AuthProto.BackofficeAuthServiceUpdateAppRequestSchema);
const fetchRoles = authServiceGrpc('fetchRole', AuthProto.BackofficeAuthServiceFetchRoleRequestSchema);
const createRole = authServiceGrpc('createRole', AuthProto.BackofficeAuthServiceCreateRoleRequestSchema);
const updateRole = authServiceGrpc('updateRole', AuthProto.BackofficeAuthServiceUpdateRoleRequestSchema);
const fetchUsers = authServiceGrpc('fetchUser', AuthProto.BackofficeAuthServiceFetchUserRequestSchema);
const createUser = authServiceGrpc('createUser', AuthProto.BackofficeAuthServiceCreateUserRequestSchema);
const updateUser = authServiceGrpc('updateUser', AuthProto.BackofficeAuthServiceUpdateUserRequestSchema);
const fetchServices = authServiceGrpc('fetchService', AuthProto.BackofficeAuthServiceFetchServiceRequestSchema);
const createService = authServiceGrpc('createService', AuthProto.BackofficeAuthServiceCreateServiceRequestSchema);
const updateService = authServiceGrpc('updateService', AuthProto.BackofficeAuthServiceUpdateServiceRequestSchema);
const fetchPaths = authServiceGrpc('fetchPath', AuthProto.BackofficeAuthServiceFetchPathRequestSchema);
const createPath = authServiceGrpc('createPath', AuthProto.BackofficeAuthServiceCreatePathRequestSchema);
const updatePath = authServiceGrpc('updatePath', AuthProto.BackofficeAuthServiceUpdatePathRequestSchema);
const fetchPolicies = authServiceGrpc('fetchPolicy', AuthProto.BackofficeAuthServiceFetchPolicyRequestSchema);
const createPolicy = authServiceGrpc('createPolicy', AuthProto.BackofficeAuthServiceCreatePolicyRequestSchema);
const updatePolicy = authServiceGrpc('updatePolicy', AuthProto.BackofficeAuthServiceUpdatePolicyRequestSchema);
const login = authServiceGrpc('login', AuthProto.BackofficeAuthServiceLoginRequestSchema);
const reloadEnforcer = authServiceGrpc('reloadEnforcer', AuthProto.BackofficeAuthServiceReloadEnforcerRequestSchema);
const fetchMe = authServiceGrpc('me', AuthProto.BackofficeAuthServiceMeRequestSchema);
// Config
const fetchConfigMail = authServiceGrpc('fetchConfigMail', AuthProto.BackofficeAuthServiceFetchConfigMailRequestSchema);
const createConfigMail = authServiceGrpc(
  'createConfigMail',
  AuthProto.BackofficeAuthServiceCreateConfigMailRequestSchema,
);
const updateConfigMail = authServiceGrpc(
  'updateConfigMail',
  AuthProto.BackofficeAuthServiceUpdateConfigMailRequestSchema,
);
const fetchConfigTemplateEmail = authServiceGrpc(
  'fetchConfigTemplateEmail',
  AuthProto.BackofficeAuthServiceFetchConfigTemplateEmailRequestSchema,
);
const createConfigTemplateEmail = authServiceGrpc(
  'createConfigTemplateEmail',
  AuthProto.BackofficeAuthServiceCreateConfigTemplateEmailRequestSchema,
);
const updateConfigTemplateEmail = authServiceGrpc(
  'updateConfigTemplateEmail',
  AuthProto.BackofficeAuthServiceUpdateConfigTemplateEmailRequestSchema,
);

// Totp
const initTotp = authServiceGrpc('initTotp', AuthProto.BackofficeAuthServiceInitTotpRequestSchema);
const verifyTotp = authServiceGrpc('verifyTotp', AuthProto.BackofficeAuthServiceVerifyTotpRequestSchema);
const removeTotp = authServiceGrpc('removeTotp', AuthProto.BackofficeAuthServiceRemoveTotpRequestSchema);

// RefCode
const updateRefCode = authServiceGrpc('updateRefCode', AuthProto.BackofficeAuthServiceUpdateRefCodeRequestSchema);

// Add refresh token service
const refreshToken = authServiceGrpc('refreshToken', AuthProto.BackofficeAuthServiceRefreshTokenRequestSchema);

// Forgot password
const forgotPassword = authServiceGrpc('forgotPassword', AuthProto.BackofficeAuthServiceForgotPasswordRequestSchema);

const authService = {
  fetchMe,
  reloadEnforcer,
  login,
  refreshToken,
  fetchPolicies,
  createPolicy,
  updatePolicy,
  fetchPaths,
  createPath,
  updatePath,
  fetchServices,
  createService,
  updateService,
  fetchUsers,
  createUser,
  updateUser,
  fetchRoles,
  createRole,
  updateRole,
  fetchApps,
  createApp,
  updateApp,
  fetchConfigMail,
  createConfigMail,
  updateConfigMail,
  fetchConfigTemplateEmail,
  createConfigTemplateEmail,
  updateConfigTemplateEmail,
  initTotp,
  verifyTotp,
  removeTotp,
  updateRefCode,
  forgotPassword,
};

export default authService;
