// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file algoenum/v1/back_connect_port_status.proto (package algoenum.v1, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file algoenum/v1/back_connect_port_status.proto.
 */
export const file_algoenum_v1_back_connect_port_status = /*@__PURE__*/
  fileDesc("CiphbGdvZW51bS92MS9iYWNrX2Nvbm5lY3RfcG9ydF9zdGF0dXMucHJvdG8SC2FsZ29lbnVtLnYxKowBChVCYWNrQ29ubmVjdFBvcnRTdGF0dXMSKAokQkFDS19DT05ORUNUX1BPUlRfU1RBVFVTX1VOU1BFQ0lGSUVEEAASIwofQkFDS19DT05ORUNUX1BPUlRfU1RBVFVTX09OTElORRABEiQKIEJBQ0tfQ09OTkVDVF9QT1JUX1NUQVRVU19PRkZMSU5FEAJCR1pFZ2l0LnRtcHJveHktaW5mcmEuY29tL2FsZ28vYWxnb3Byb3h5LXByb3RvL2dlbi9hbGdvZW51bS92MTthbGdvZW51bXYxYgZwcm90bzM");

/**
 * Describes the enum algoenum.v1.BackConnectPortStatus.
 */
export const BackConnectPortStatusSchema = /*@__PURE__*/
  enumDesc(file_algoenum_v1_back_connect_port_status, 0);

/**
 * @generated from enum algoenum.v1.BackConnectPortStatus
 */
export const BackConnectPortStatus = /*@__PURE__*/
  tsEnum(BackConnectPortStatusSchema);

