// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file algoenum/v1/proxy_protocol.proto (package algoenum.v1, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file algoenum/v1/proxy_protocol.proto.
 */
export const file_algoenum_v1_proxy_protocol = /*@__PURE__*/
  fileDesc("CiBhbGdvZW51bS92MS9wcm94eV9wcm90b2NvbC5wcm90bxILYWxnb2VudW0udjEqYwoNUHJveHlQcm90b2NvbBIeChpQUk9YWV9QUk9UT0NPTF9VTlNQRUNJRklFRBAAEhcKE1BST1hZX1BST1RPQ09MX0hUVFAQARIZChVQUk9YWV9QUk9UT0NPTF9TT0NLUzUQAkJHWkVnaXQudG1wcm94eS1pbmZyYS5jb20vYWxnby9hbGdvcHJveHktcHJvdG8vZ2VuL2FsZ29lbnVtL3YxO2FsZ29lbnVtdjFiBnByb3RvMw");

/**
 * Describes the enum algoenum.v1.ProxyProtocol.
 */
export const ProxyProtocolSchema = /*@__PURE__*/
  enumDesc(file_algoenum_v1_proxy_protocol, 0);

/**
 * @generated from enum algoenum.v1.ProxyProtocol
 */
export const ProxyProtocol = /*@__PURE__*/
  tsEnum(ProxyProtocolSchema);

