import Pages from '@/pages';

import { ROUTES } from './routes';

export const ROUTER = [
  {
    path: ROUTES.AUTH,
    component: Pages.Auth,
    isPrivate: false,
  },
  {
    path: ROUTES.ACCOUNTS_USER,
    component: Pages.Users,
    isPrivate: true,
  },
  {
    path: ROUTES.ACCOUNTS_ROLES,
    component: Pages.Roles,
    isPrivate: true,
  },
  {
    path: ROUTES.ACCOUNTS_SERVICES,
    component: Pages.Services,
    isPrivate: true,
  },
  {
    path: ROUTES.ACCOUNTS_PATHS,
    component: Pages.Paths,
    isPrivate: true,
  },
  {
    path: ROUTES.ACCOUNTS_POLICIES,
    component: Pages.Policies,
    isPrivate: true,
  },
  {
    path: ROUTES.ACCOUNTS_APPS,
    component: Pages.Apps,
    isPrivate: true,
  },
  {
    path: ROUTES.ACCOUNTS_CONFIGS,
    component: Pages.Configs,
    isPrivate: true,
  },
  {
    path: ROUTES.ACCOUNTS_TOTP,
    component: Pages.Totp,
    isPrivate: true,
  },
  {
    path: ROUTES.CONTROL_PLAN_DNS,
    component: Pages.DNS,
    isPrivate: true,
  },
  {
    path: ROUTES.CONTROL_PLAN_TELCO,
    component: Pages.Telco,
    isPrivate: true,
  },
  {
    path: ROUTES.CONTROL_PLAN_LOCATION,
    component: Pages.Location,
    isPrivate: true,
  },
  {
    path: ROUTES.RESIDENTIAL_ACCOUNT,
    component: Pages.ResidentialAccount,
    isPrivate: true,
  },
  {
    path: ROUTES.RESIDENTIAL_NODE,
    component: Pages.ResidentialNode,
    isPrivate: true,
  },
  {
    path: ROUTES.RESIDENTIAL_PORT,
    component: Pages.ResidentialPort,
    isPrivate: true,
  },
  {
    path: ROUTES.RESIDENTIAL_DEVICE,
    component: Pages.ResidentialDevice,
    isPrivate: true,
  },
  {
    path: ROUTES.BILLINGS_MERCHANT,
    component: Pages.BillingMerchant,
    isPrivate: true,
  },
  {
    path: ROUTES.BILLINGS_MERCHANT_PLAN,
    component: Pages.BillingPlan,
    isPrivate: true,
  },
  {
    path: ROUTES.BILLINGS_TRANSACTION,
    component: Pages.BillingTransaction,
    isPrivate: true,
  },
  {
    path: ROUTES.BILLINGS_PAYMENTS,
    component: Pages.BillingPayments,
    isPrivate: true,
  },
  {
    path: ROUTES.BILLINGS_SUBSCRIPTION,
    component: Pages.BillingSubscription,
    isPrivate: true,
  },
  {
    path: ROUTES.BILLINGS_MERCHANT_PROFILE,
    component: Pages.ProxyProfile,
    isPrivate: true,
  },
  {
    path: ROUTES.PROXY_MANAGER_BACK_CONNECT,
    component: Pages.ProxyManagerBackConnect,
    isPrivate: true,
  },
];
