import { store } from '@/reducers';
import { ActionType } from '@/reducers/user';
import { algoService, authService } from '@/services';
import { COOKIE_NAME, REFRESH_TOKEN_NAME, TOKEN_NAME } from '@/utils/constants';
import { generateDeviceIdFromUserAgent } from '@/utils/helper/string';

const login = async (values) => {
  const { user } = store.getState();
  if (user && user.isLoggedIn) {
    return user;
  }
  try {
    const result = await authService.login(values);
    if (result?.token) {
      signIn({
        accessToken: result?.token,
        refreshToken: result?.refreshToken,
      });
      localStorage.setItem(TOKEN_NAME, result?.token);
      localStorage.setItem(REFRESH_TOKEN_NAME, result?.refreshToken);
      const resultMe = await authService.fetchMe();
      if (resultMe?.paths) {
        const loginUser = {
          email: values?.email,
          accessToken: result?.token,
          refreshToken: result?.refreshToken,
          listPath: resultMe?.paths,
          isLoggedIn: true,
          userDetail: resultMe?.userDetail,
        };
        signIn(loginUser);
        localStorage.setItem(COOKIE_NAME, JSON.stringify(loginUser));
        return loginUser;
      }
    }
    return result;
  } catch (error) {
    return undefined;
  }
};

const fetchConfig = async () => {
  const { user } = store.getState();
  const configResult = await algoService.fetchConfig();
  if (configResult?.data) {
    signIn({
      config: configResult?.data,
    });
    localStorage.setItem(COOKIE_NAME, JSON.stringify({ ...user, config: configResult.data }));
  }
};

const changePassword = async (values) => {
  const result = await algoService.changePassword(values);
  return result;
};

const signIn = (data) => {
  store.dispatch({
    type: ActionType.USER_LOGIN,
    data,
  });
};

const refreshTokenAction = async () => {
  try {
    const refreshTokenValue = localStorage.getItem(REFRESH_TOKEN_NAME);
    if (!refreshTokenValue) {
      signOut();
      return false;
    }

    const result = await authService.refreshToken({
      refreshToken: refreshTokenValue,
      deviceId: generateDeviceIdFromUserAgent(),
    });

    if (result?.token) {
      localStorage.setItem(TOKEN_NAME, result.token);
      localStorage.setItem(REFRESH_TOKEN_NAME, result.refreshToken);

      const { user } = store.getState();
      const updatedUser = {
        ...user,
        accessToken: result.token,
        refreshToken: result.refreshToken,
      };

      signIn(updatedUser);
      localStorage.setItem(COOKIE_NAME, JSON.stringify(updatedUser));
      return true;
    }

    return false;
  } catch (error) {
    signOut();
    return false;
  }
};

const signOut = () => {
  localStorage.removeItem(COOKIE_NAME);
  localStorage.removeItem(TOKEN_NAME);
  localStorage.removeItem(REFRESH_TOKEN_NAME);
  store.dispatch({
    type: ActionType.USER_LOGOUT,
  });
};

const forgotPassword = async (values) => {
  try {
    const result = await authService.forgotPassword(values);
    return result;
  } catch (error) {
    return error;
  }
};

export const userAction = {
  login,
  signOut,
  signIn,
  changePassword,
  fetchConfig,
  refreshTokenAction,
  forgotPassword,
};
