import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';

import React from 'react';

import { ROUTES } from './router/routes';
import { isHasPermissionPage } from './utils/authentication';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const { isLoggedIn, listPath } = useSelector(({ user }) => user);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn && isHasPermissionPage(location.pathname, listPath) ? (
          <Component {...props} />
        ) : (
          <Redirect to={ROUTES.AUTH_LOGIN} />
        )
      }
    />
  );
};

export default PrivateRoute;
