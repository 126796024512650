// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file algoenum/v1/location_level.proto (package algoenum.v1, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file algoenum/v1/location_level.proto.
 */
export const file_algoenum_v1_location_level = /*@__PURE__*/
  fileDesc("CiBhbGdvZW51bS92MS9sb2NhdGlvbl9sZXZlbC5wcm90bxILYWxnb2VudW0udjEqswEKDUxvY2F0aW9uTGV2ZWwSHgoaTE9DQVRJT05fTEVWRUxfVU5TUEVDSUZJRUQQABIYChRMT0NBVElPTl9MRVZFTF9XT1JMRBABEhkKFUxPQ0FUSU9OX0xFVkVMX1JFR0lPThACEhoKFkxPQ0FUSU9OX0xFVkVMX0NPVU5UUlkQAxIYChRMT0NBVElPTl9MRVZFTF9TVEFURRAEEhcKE0xPQ0FUSU9OX0xFVkVMX0NJVFkQBUJHWkVnaXQudG1wcm94eS1pbmZyYS5jb20vYWxnby9hbGdvcHJveHktcHJvdG8vZ2VuL2FsZ29lbnVtL3YxO2FsZ29lbnVtdjFiBnByb3RvMw");

/**
 * Describes the enum algoenum.v1.LocationLevel.
 */
export const LocationLevelSchema = /*@__PURE__*/
  enumDesc(file_algoenum_v1_location_level, 0);

/**
 * @generated from enum algoenum.v1.LocationLevel
 */
export const LocationLevel = /*@__PURE__*/
  tsEnum(LocationLevelSchema);

