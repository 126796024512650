import ForgotPassword from '@/pages/Auth/ForgotPassword/Loadable';
import Auth from '@/pages/Auth/Loadable';
import Login from '@/pages/Auth/Login/Loadable';
import BillingMerchant from '@/pages/Billing/MerchantConfig/Loadable';
import BillingPayments from '@/pages/Billing/Payments/Loadable';
import BillingPlan from '@/pages/Billing/Plan/Loadable';
import BillingSubscription from '@/pages/Billing/Subscription/Loadable';
import BillingTransaction from '@/pages/Billing/Transaction/Loadable';
import DNS from '@/pages/DNS/Loadable';
import Location from '@/pages/Location/Loadable';
import Apps from '@/pages/ManageAccount/Apps/Loadable';
import Configs from '@/pages/ManageAccount/Configs/Loadable';
import Paths from '@/pages/ManageAccount/Paths/Loadable';
import Policies from '@/pages/ManageAccount/Policies/Loadable';
import Roles from '@/pages/ManageAccount/Roles/Loadable';
import Services from '@/pages/ManageAccount/Services/Loadable';
import Totp from '@/pages/ManageAccount/Totp/Loadable';
import Users from '@/pages/ManageAccount/Users/Loadable';
import ProxyManagerBackConnect from '@/pages/ProxyManager/BackConnect/Loadable';
import ProxyProfile from '@/pages/ProxyManager/ProxyProfile/Loadable';
import ResidentialAccount from '@/pages/Residential/Account/Loadable';
import ResidentialDevice from '@/pages/Residential/Device/Loadable';
import ResidentialNode from '@/pages/Residential/Node/Loadable';
import ResidentialPort from '@/pages/Residential/Port/Loadable';
import Telco from '@/pages/Telco/Loadable';

const pages = {
  BillingMerchant,
  BillingPayments,
  BillingPlan,
  BillingSubscription,
  BillingTransaction,
  DNS,
  Location,
  Auth,
  Login,
  ForgotPassword,
  Apps,
  Paths,
  Policies,
  Roles,
  Services,
  Users,
  ResidentialAccount,
  ResidentialDevice,
  ResidentialNode,
  ResidentialPort,
  Telco,
  Configs,
  ProxyProfile,
  ProxyManagerBackConnect,
  Totp,
};

export default pages;
