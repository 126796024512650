// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file proxymanager/telco/v1/backoffice.proto (package proxymanager.telco.v1, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import { file_errmsg_v1_errormsg } from "../../../errmsg/v1/errormsg_pb";
import { file_utils_v1_utils } from "../../../utils/v1/utils_pb";

/**
 * Describes the file proxymanager/telco/v1/backoffice.proto.
 */
export const file_proxymanager_telco_v1_backoffice = /*@__PURE__*/
  fileDesc("CiZwcm94eW1hbmFnZXIvdGVsY28vdjEvYmFja29mZmljZS5wcm90bxIVcHJveHltYW5hZ2VyLnRlbGNvLnYxIoEBCidCYWNrb2ZmaWNlVGVsY29TZXJ2aWNlRmV0Y2hUZWxjb1JlcXVlc3QSEAoIaWRfdGVsY28YASABKAkSEwoLbmFtZV9zZWFyY2gYAiABKAkSLwoKcGFnaW5hdGlvbhgDIAEoCzIbLnV0aWxzLnYxLlBhZ2luYXRpb25SZXF1ZXN0IsABCihCYWNrb2ZmaWNlVGVsY29TZXJ2aWNlRmV0Y2hUZWxjb1Jlc3BvbnNlEiYKBWVycm9yGAEgASgLMhcuZXJybXNnLnYxLkVycm9yTWVzc2FnZRIwCgpwYWdpbmF0aW9uGAIgASgLMhwudXRpbHMudjEuUGFnaW5hdGlvblJlc3BvbnNlEjoKBWl0ZW1zGAMgAygLMisucHJveHltYW5hZ2VyLnRlbGNvLnYxLkJhY2tvZmZpY2VUZWxjb01vZGVsIjgKKEJhY2tvZmZpY2VUZWxjb1NlcnZpY2VDcmVhdGVUZWxjb1JlcXVlc3QSDAoEbmFtZRgBIAEoCSJTCilCYWNrb2ZmaWNlVGVsY29TZXJ2aWNlQ3JlYXRlVGVsY29SZXNwb25zZRImCgVlcnJvchgBIAEoCzIXLmVycm1zZy52MS5FcnJvck1lc3NhZ2UiYgooQmFja29mZmljZVRlbGNvU2VydmljZVVwZGF0ZVRlbGNvUmVxdWVzdBIQCghpZF90ZWxjbxgBIAEoCRIMCgRuYW1lGAIgASgJEhYKDmlkX2RlZmF1bHRfZG5zGAMgASgJIlMKKUJhY2tvZmZpY2VUZWxjb1NlcnZpY2VVcGRhdGVUZWxjb1Jlc3BvbnNlEiYKBWVycm9yGAEgASgLMhcuZXJybXNnLnYxLkVycm9yTWVzc2FnZSJOChRCYWNrb2ZmaWNlVGVsY29Nb2RlbBIQCghpZF90ZWxjbxgBIAEoCRIMCgRuYW1lGAIgASgJEhYKDmlkX2RlZmF1bHRfZG5zGAMgASgJMs4DChZCYWNrb2ZmaWNlVGVsY29TZXJ2aWNlEo0BCgpGZXRjaFRlbGNvEj4ucHJveHltYW5hZ2VyLnRlbGNvLnYxLkJhY2tvZmZpY2VUZWxjb1NlcnZpY2VGZXRjaFRlbGNvUmVxdWVzdBo/LnByb3h5bWFuYWdlci50ZWxjby52MS5CYWNrb2ZmaWNlVGVsY29TZXJ2aWNlRmV0Y2hUZWxjb1Jlc3BvbnNlEpABCgtDcmVhdGVUZWxjbxI/LnByb3h5bWFuYWdlci50ZWxjby52MS5CYWNrb2ZmaWNlVGVsY29TZXJ2aWNlQ3JlYXRlVGVsY29SZXF1ZXN0GkAucHJveHltYW5hZ2VyLnRlbGNvLnYxLkJhY2tvZmZpY2VUZWxjb1NlcnZpY2VDcmVhdGVUZWxjb1Jlc3BvbnNlEpABCgtVcGRhdGVUZWxjbxI/LnByb3h5bWFuYWdlci50ZWxjby52MS5CYWNrb2ZmaWNlVGVsY29TZXJ2aWNlVXBkYXRlVGVsY29SZXF1ZXN0GkAucHJveHltYW5hZ2VyLnRlbGNvLnYxLkJhY2tvZmZpY2VUZWxjb1NlcnZpY2VVcGRhdGVUZWxjb1Jlc3BvbnNlQk5aTGdpdC50bXByb3h5LWluZnJhLmNvbS9hbGdvL2FsZ29wcm94eS1wcm90by9nZW4vcHJveHltYW5hZ2VyL3RlbGNvL3YxO3RlbGNvdjFiBnByb3RvMw", [file_errmsg_v1_errormsg, file_utils_v1_utils]);

/**
 * Describes the message proxymanager.telco.v1.BackofficeTelcoServiceFetchTelcoRequest.
 * Use `create(BackofficeTelcoServiceFetchTelcoRequestSchema)` to create a new message.
 */
export const BackofficeTelcoServiceFetchTelcoRequestSchema = /*@__PURE__*/
  messageDesc(file_proxymanager_telco_v1_backoffice, 0);

/**
 * Describes the message proxymanager.telco.v1.BackofficeTelcoServiceFetchTelcoResponse.
 * Use `create(BackofficeTelcoServiceFetchTelcoResponseSchema)` to create a new message.
 */
export const BackofficeTelcoServiceFetchTelcoResponseSchema = /*@__PURE__*/
  messageDesc(file_proxymanager_telco_v1_backoffice, 1);

/**
 * Describes the message proxymanager.telco.v1.BackofficeTelcoServiceCreateTelcoRequest.
 * Use `create(BackofficeTelcoServiceCreateTelcoRequestSchema)` to create a new message.
 */
export const BackofficeTelcoServiceCreateTelcoRequestSchema = /*@__PURE__*/
  messageDesc(file_proxymanager_telco_v1_backoffice, 2);

/**
 * Describes the message proxymanager.telco.v1.BackofficeTelcoServiceCreateTelcoResponse.
 * Use `create(BackofficeTelcoServiceCreateTelcoResponseSchema)` to create a new message.
 */
export const BackofficeTelcoServiceCreateTelcoResponseSchema = /*@__PURE__*/
  messageDesc(file_proxymanager_telco_v1_backoffice, 3);

/**
 * Describes the message proxymanager.telco.v1.BackofficeTelcoServiceUpdateTelcoRequest.
 * Use `create(BackofficeTelcoServiceUpdateTelcoRequestSchema)` to create a new message.
 */
export const BackofficeTelcoServiceUpdateTelcoRequestSchema = /*@__PURE__*/
  messageDesc(file_proxymanager_telco_v1_backoffice, 4);

/**
 * Describes the message proxymanager.telco.v1.BackofficeTelcoServiceUpdateTelcoResponse.
 * Use `create(BackofficeTelcoServiceUpdateTelcoResponseSchema)` to create a new message.
 */
export const BackofficeTelcoServiceUpdateTelcoResponseSchema = /*@__PURE__*/
  messageDesc(file_proxymanager_telco_v1_backoffice, 5);

/**
 * Describes the message proxymanager.telco.v1.BackofficeTelcoModel.
 * Use `create(BackofficeTelcoModelSchema)` to create a new message.
 */
export const BackofficeTelcoModelSchema = /*@__PURE__*/
  messageDesc(file_proxymanager_telco_v1_backoffice, 6);

/**
 * @generated from service proxymanager.telco.v1.BackofficeTelcoService
 */
export const BackofficeTelcoService = /*@__PURE__*/
  serviceDesc(file_proxymanager_telco_v1_backoffice, 0);

