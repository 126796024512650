import { combineReducers, createStore } from 'redux';

import system from './system';
import user from './user';

export const store = createStore(
  combineReducers({
    user,
    system,

  }),
  {},
);
