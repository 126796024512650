// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file algoenum/v1/template_email.proto (package algoenum.v1, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file algoenum/v1/template_email.proto.
 */
export const file_algoenum_v1_template_email = /*@__PURE__*/
  fileDesc("CiBhbGdvZW51bS92MS90ZW1wbGF0ZV9lbWFpbC5wcm90bxILYWxnb2VudW0udjEqggEKEVRlbXBsYXRlRW1haWxUeXBlEiMKH1RFTVBMQVRFX0VNQUlMX1RZUEVfVU5TUEVDSUZJRUQQABIbChdURU1QTEFURV9FTUFJTF9UWVBFX09UUBABEisKJ1RFTVBMQVRFX0VNQUlMX1RZUEVfT1RQX0ZPUkdPVF9QQVNTV09SRBACQkdaRWdpdC50bXByb3h5LWluZnJhLmNvbS9hbGdvL2FsZ29wcm94eS1wcm90by9nZW4vYWxnb2VudW0vdjE7YWxnb2VudW12MWIGcHJvdG8z");

/**
 * Describes the enum algoenum.v1.TemplateEmailType.
 */
export const TemplateEmailTypeSchema = /*@__PURE__*/
  enumDesc(file_algoenum_v1_template_email, 0);

/**
 * @generated from enum algoenum.v1.TemplateEmailType
 */
export const TemplateEmailType = /*@__PURE__*/
  tsEnum(TemplateEmailTypeSchema);

